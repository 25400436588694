
































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import darkTheme from 'highcharts/themes/dark-unica';
import useAppConfig from '../@core/app-config/useAppConfig';
import moment from 'moment';
import axios from 'axios';
import highcharts from 'highcharts';
import highchartMore from 'highcharts/highcharts-more';
import { MonitorType } from './types';

export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    const range = [
      moment().startOf('days').subtract(1, 'days').valueOf(),
      moment().startOf('days').valueOf(),
    ];
    let monitor: string | undefined = 'me';
    let monitorType: string = 'WD_SPEED';
    let nWays = [8, 16, 32];
    let errorMsg: string = '';
    return {
      display: false,
      form: {
        monitor,
        monitorType,
        nWay: 8,
        range,
      },
      nWays,
      errorMsg,
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
    ...mapState('monitors', ['monitors']),
    ...mapGetters('monitorTypes', ['mtMap', 'activatedMonitorTypes']),
    canQuery(): boolean {
      if (this.form.monitorType == undefined) return false;

      return true;
    },
    levelMt(): Array<MonitorType> {
      return this.monitorTypes.filter(
        (mt: MonitorType) => mt.levels !== undefined,
      );
    },
  },
  async mounted() {
    const { skin } = useAppConfig();
    if (skin.value == 'dark') {
      darkTheme(highcharts);
    }

    await this.fetchMonitorTypes();
    await this.fetchMonitors();
  },
  methods: {
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    ...mapActions('monitors', ['fetchMonitors']),
    ...mapMutations(['setLoading']),
    async query() {
      this.setLoading({ loading: true });
      this.display = true;

      try {
        const url = `/WindRose/${this.form.monitor}/${this.form.monitorType}/hour/${this.form.nWay}/${this.form.range[0]}/${this.form.range[1]}`;
        const res = await axios.get(url);
        const ret = res.data;
        ret.pane = {
          size: '90%',
        };

        ret.legend = {
          align: 'right',
          verticalAlign: 'top',
          y: 100,
          layout: 'vertical',
        };
        ret.yAxis = {
          min: 0,
          endOnTick: false,
          showLastLabel: true,
          title: {
            text: '頻率 (%)',
          },
          labels: {
            formatter(this: any) {
              return this.value + '%';
            },
          },
          reversedStacks: false,
        };

        ret.tooltip = {
          valueDecimals: 2,
          valueSuffix: '%',
        };

        ret.plotOptions = {
          series: {
            stacking: 'normal',
            shadow: false,
            groupPadding: 0,
            pointPlacement: 'on',
          },
        };

        ret.credits = {
          enabled: false,
          href: 'http://www.wecc.com.tw/',
        };

        ret.title.x = -70;
        highchartMore(highcharts);
        highcharts.chart('chart_container', ret);
      } catch (err) {
        this.$bvModal.msgBoxOk('沒有資料');
      } finally {
        this.setLoading({ loading: false });
      }
    },
  },
});
